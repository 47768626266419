<template>
    <div class="container2">
        <header class="header sticky-bar" style="background-color: #37663c; color:white !important;">
            <div class="container">
                <div class="pt-10 pb-10 topNav" ref="topNav">
                    <div class="row">
                        <div class="col-7 col-md-3">
                            <a href="/" style="color:white;">
                                <div style="display:inline-block; align: center;vertical-align: middle;">
                                    <img alt="RangeerSpoor" src="/logo.png" width="30" />
                                </div>
                                <div style="display:inline-block;vertical-align: middle; font-size:22px;" class="pl-10">
                                    {{ $t('global.rs') }}
                                </div>
                            </a>

                        </div>
                        <div class="d-none d-xl-block col-md-7 ">
                            <nav class="nav-main-menu d-none d-xl-block">
                                <ul class="main-menu">
                                    <li><router-link :to="{name: 'home'}">{{ $t('global.home') }}</router-link></li>
                                    <li><router-link :to="{name: 'items'}">{{ $t('global.items') }}</router-link></li>
                                    <li><router-link :to="{name: 'inventory'}">{{ $t('global.inventory') }}</router-link></li>
                                    <li><router-link :to="{name: 'wishlists'}">{{ $t('global.wishlists') }}</router-link></li>
                                    <li><router-link :to="{name: 'listings'}">{{ $t('global.ads') }}</router-link></li>
                                    <li v-if="isLoggedIn">
                                        <router-link style="background-color: #ff7900; padding: 5px 10px; color: white;" 
                                                     :to="{name: 'createListing'}" 
                                                     v-on:click="menuOpen=false;">
                                            + {{ $t('global.ad') }}
                                        </router-link>

                                    </li>
                                    <li  v-if="!isLoggedIn" >
                                        <router-link style="background-color: #ff7900; padding: 5px 10px; color: white;" 
                                                     :to="{name: 'register'}" 
                                                     class="register  mr-10"
                                                     href="/registreren">
                                            <span><font-awesome-icon icon="fa-solid fa-user" class="mr-5" />{{ $t('global.newAccount') }}</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="col-5 col-md-2 pt-5" style="text-align:right">
                            <div>
                                <router-link v-if="!isLoggedIn" :to="{name: 'register'}" class="active btn  d-sm-none register mr-10"
                                             href="/registreren">
                                    <span><font-awesome-icon icon="fa-solid fa-user" class="mr-5" />+</span>
                                </router-link>

                               

                                <span id="settingsMenuBtn" v-on:click="settingsMenuOpen = !settingsMenuOpen" class="mr-10">
                                    <font-awesome-icon icon="fa-solid fa-cog" size="xl" />
                                </span>
                                <router-link v-if="isLoggedIn" :to="{name: 'dashboard'}" style="color: white !important " class="mr-10" v-on:click="menuOpen=false;">
                                    <font-awesome-icon icon="fa-solid fa-user" size="xl" />
                                </router-link>
                                <span id="navMenuBtn" v-on:click="menuOpen = !menuOpen">
                                    <font-awesome-icon ref="navMenuBtn" icon="fa-solid fa-bars" size="xl" />
                                </span>
                            </div>
                            <div class=" dropdown-settings" id="settingsMenu" style="" v-if="settingsMenuOpen" ref="settingsMenu" :style="{right:menuPosition + 'px'}">
                                <div class="mb-10">
                                    {{ $t('global.language') }}:
                                </div>
                                <ul>
                                    <li v-for="lang in languages" v-bind:key="lang" v-on:click="setLanguage(lang)">
                                        <img class="flag mr-5" :src="'/flag-' +lang+ '.svg'" />
                                        
                                        <span :class="$i18n.locale == lang ? 'bold' : ''">{{ $t('languages.' + lang) }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div ref="navMenu">
                                <div class="dropdown-account" style="" v-if="menuOpen" id="navMenu" :style="{right:menuPosition + 'px'}">
                                    <ul>
                                        <li><router-link :to="{name: 'home'}" v-on:click="menuOpen=false;">{{ $t('global.home') }}</router-link></li>
                                        <li><router-link :to="{name: 'listings'}" v-on:click="menuOpen=false;">{{ $t('global.ads') }}</router-link></li>
                                        <li v-if="isLoggedIn"><router-link :to="{name: 'createListing'}" v-on:click="menuOpen=false;">{{ $t('global.newAd') }}</router-link></li>
                                        <li><router-link :to="{name: 'items'}" v-on:click="menuOpen=false;">{{ $t('global.items') }}</router-link></li>
                                        <li><router-link :to="{name: 'wishlists'}" v-on:click="menuOpen=false;">{{ $t('global.wishlists') }}</router-link></li>
                                        <li><router-link :to="{name: 'inventory'}" v-on:click="menuOpen=false;">{{ $t('global.inventory') }}</router-link></li>
                                        <li v-if="isLoggedIn"><router-link :to="{name: 'recommendations'}" v-on:click="menuOpen=false;">{{ $t('global.recommendations') }}</router-link></li>

                                        <li v-if="isLoggedIn"><router-link :to="{name: 'messages'}" v-on:click="menuOpen=false;">{{ $t('global.messages') }}</router-link></li>

                                        <li v-if="isLoggedIn" class="border-top pt-10"><router-link :to="{name: 'logout'}" v-on:click="menuOpen=false;">{{ $t('global.logout') }}</router-link></li>
                                        <li v-if="!isLoggedIn" class="border-top pt-10"><router-link :to="{name: 'login'}" v-on:click="menuOpen=false;">{{ $t('global.login') }}</router-link></li>
                                        <li v-if="!isLoggedIn" class=""><router-link :to="{name: 'register'}" v-on:click="menuOpen=false;">{{ $t('global.register') }}</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="d-block d-sm-none" v-if="$route.name == 'home'">

            <ul class="navContainer ">

                <!--<li><router-link :to="{name: 'home'}"><font-awesome-icon icon="list" class="mr-5" />{{ $t('global.home') }}</router-link></li>-->
                <li><router-link :to="{name: 'items'}"><font-awesome-icon icon="table-list" class="mr-5" />{{ $t('global.items') }}</router-link></li>
                <li><router-link :to="{name: 'listings'}"><font-awesome-icon icon="fa-grip-vertical" class="mr-5" />{{ $t('global.ads') }}</router-link></li>

                <li><router-link :to="{name: 'inventory'}"><font-awesome-icon icon="fa-list-check" class="mr-5" />{{ $t('global.inventory') }}</router-link></li>
                <li><router-link :to="{name: 'wishlists'}"><font-awesome-icon icon="fa-heart" class="mr-5" />{{ $t('global.wishlists') }}</router-link></li>
                <li><router-link :to="{name: 'favorites'}"><font-awesome-icon icon="fa-star" class="mr-5" />{{ $t('global.favorites') }}</router-link></li>
            </ul>
        </div>

        <main class="main mb-10">
            <div class="section-box" v-if="route != ''">
                <div class="breadcrumbs-div">
                    <div class="container">
                        <ul class="breadcrumb">
                            <li>
                                <router-link class="color-gray-1000" :to="{name:'home'}">{{ $t('global.home') }}</router-link>
                            </li>
                            <li class="ml-10 mr-10">
                                &raquo;

                            </li>
                            <li v-if="route != ''">
                                <!--{{ $t('global.home') }}-->
                                <a href="#" class="color-gray-1000"> {{$t(route)}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <section class="section-box shop-template">
                <div class="container">
                    <router-view></router-view>
                </div>
            </section>
        </main>

        <footer class="footer">
            <div class="footer-1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 width-25 mb-20 mt-20">
                            <h4 class="mb-30 color-gray-1000">{{ $t('global.contact') }}</h4>
                            <div class="font-md mb-20 color-gray-900"><strong class="font-md-bold">{{ $t('global.email') }}:</strong> info@rangeerspoor.nl</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>


<script>
    import { UserClient } from '@/httpClients/userClient.js'

    export default {
        name: 'wishlist',

        data() {
            return {
                menuOpen: false,
                settingsMenuOpen: false,

                route: '',
                user: null,
                isLoggedIn: false,
                menuPosition: 0,
                theme: 'light',
                languages: ["nl", "de", "en"]
            }
        },
        watch: {
            $route() {

                this.route = this.GetName();
                this.$setRoutingName(this.$router.options.history.state.back, this.GetBackName(this.$router.options.history.state.back))

                if (typeof (this.route) != "undefined" && this.route != '') {
                    document.title = "Rangeerspoor.nl - " + this.route;
                } else {
                    document.title = "Rangeerspoor.nl";
                }
            },
            menuOpen() {
                //const left = this.$refs.topNav.getBoundingClientRect().right
                //var totalWidth = window.innerWidth;
                //console.log(left);
                //var offset = totalWidth - left;
                //this.menuPosition = offset;
            }

        },
        methods: {
            setLanguage: function (lang) {
                this.$i18n.locale = lang;
                localStorage.setItem("lang", lang);

            },
            async loadUser() {
                try {
                    this.isLoggedIn = await UserClient.IsLoggedIn();
                    return true;
                } catch (ex) {
                    console.log(ex);
                    return false;
                }
            },
            GetBackName: function (route) {
                if (route == null) {
                    return;
                }
                var split1 = route.split('?');
                var split = split1[0].split('/');
                switch (split[1]) {
                    case "item": return "item";
                    default: return split[1];
                }
            },
            isDescendant(parent, child) {
                var node = child.parentNode;

                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            },
            GetName: function () {
                switch (this.$router.currentRoute.value.name) {
                    case "home": return "";
                    default: return this.$i18n.t('global.' + this.$router.currentRoute.value.name);

                }
            }
        },
        beforeRouteEnter(_, from, next) {
            next(vm => {
                if (typeof (from.name) === 'undefined') {
                    vm.prevRoute.displayName = "vorige pagina";
                    vm.prevRoute.fullPath = vm.$router.options.history.state.back;
                    return;
                }
            })
        },
        setup() {

        },
        mounted() {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.theme = "dark";
            } else {
                this.theme = "light";

            }

            document.body.classList.add(this.theme);
            this.loadUser();


            document.addEventListener("click", (e) => {
                const parent = document.getElementById("navMenu");
                const parent2 = document.getElementById("navMenuBtn");

                var isDescendant = this.isDescendant(parent, e.target);
                var isDescendant2 = this.isDescendant(parent2, e.target);

                if (isDescendant2 || isDescendant) {
                    return;
                }


                this.menuOpen = false;
            });

            document.addEventListener("click", (e) => {
                const parent = document.getElementById("settingsMenu");
                const parent2 = document.getElementById("settingsMenuBtn");

                var isDescendant = this.isDescendant(parent, e.target);
                var isDescendant2 = this.isDescendant(parent2, e.target);
                if (isDescendant2 || isDescendant) {
                    return;
                }
                this.settingsMenuOpen = false;
            });

        }
    }
</script>

<style scoped>
    .footer {
        margin-top: auto;
    }
</style>

<style>
    @import '@/css/style3.css';
    @import '@/css/style2.css';
    @import '@/css/light.css';
    @import '@/css/dark.css';

    .content {
        border: 1px solid #dadada;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
    }

    .dropdown-settings {
        text-align: left;
    }

    .flag {
        width: 20px;
        vertical-align: middle;
    }

    .dropdown-settings ul li {
        margin-bottom: 10px;
    }

    .register {
        background-color: rgb(255, 121, 0);
        padding: 5px 10px;
        font-size: 1em;
        color: white;
        font-weight: bold;
        text-align: center;
    }

    .navContainer li {
        /*color: black;*/
        background-color: white;
        padding: 5px 20px 5px 20px;
        border-radius: 4px;
        text-align: center;
        float: left;
        margin-right: 10px;
    }

    .navContainer {
        overflow: scroll;
        background-color: #698C6C;
        width: 100%;
        white-space: nowrap;
        overflow-x: scroll;
        display: inline-flex;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
    }

        .navContainer::-webkit-scrollbar {
            display: none;
        }
        .bold{
            font-weight: bold;
        }
</style>