import axios from 'axios'

const refreshTokenEndpoint = 'users/refresh-token';

let baseUrl = process.env.VUE_APP_BASE_URL;

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
});

instance.interceptors.request.use(function (config) {
    config.headers.Authorization = "Bearer " + localStorage.accessToken;
    return config;
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        if (typeof (localStorage.accessToken) === "undefined") {
            return;
        }

        if (error.response.status === 401 && !originalRequest.retry) {
            originalRequest.retry = true;
            return refreshAccessToken().then(() => {
                originalRequest.headers.Authorization = `Bearer ${localStorage.accessToken}`;
                return axios(originalRequest);
            });
        }

        return Promise.reject(error);
    }
);

async function refreshAccessToken() {
    try {
        const response = await instance.post(baseUrl + refreshTokenEndpoint, {
            AccessToken: localStorage.accessToken,
            RefreshToken: localStorage.refreshToken
        });
        localStorage.accessToken = response.data.accessToken;
        localStorage.refreshToken = response.data.refreshToken;
    } catch (err) {
        //throw err;

    }
}


export default instance;